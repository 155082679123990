<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      :search="search"
      sort-by="id_mex"
      class="elevation-1"
      :footer-props="{ itemsPerPageText: 'Filas por página:' }"
      :items-per-page="-1"
    >
      <template v-slot:top>
        <v-card-title>
          <v-toolbar dense flat>
            <v-btn small icon>
              <v-icon>mdi-clipboard-list-outline</v-icon>
            </v-btn>
            <v-toolbar-title>Lista</v-toolbar-title>
            <v-divider class="mx-4" vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-toolbar>
        </v-card-title>
      </template>
      <template v-slot:[`item.accion`]="{ item }">
        <template v-if="item.accion == 'true'">
          <v-btn icon @click="check(item.id_mex, 'false')">
            <v-icon color="success">mdi-checkbox-blank</v-icon>
          </v-btn>
        </template>
        <template v-else>
          <v-btn icon @click="check(item.id_mex, 'true')">
            <v-icon color="gray">mdi-checkbox-blank-outline</v-icon>
          </v-btn>
        </template>
      </template>
      <template v-slot:no-data>
        <br />
        <p>Sin datos disponibles</p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  data() {
    return {
      headers: [
        { text: "Id", value: "id_mex", align: "center" },
        { text: "Nombre", value: "nombre", align: "center" },
        {
          text: "Registro",
          value: "accion",
          align: "center",
          sortable: false,
        },
      ],
      items: [],
      search: "",
    };
  },
  created() {
    this.$connectionService.start();
    Vue.$connectionService.events.on("getlista", (values) => {
      this.refresh(values);
    });
    Vue.$connectionService.events.on("check", (values) => {
      if (values == "si") {
        this.funcionnormal();
      }
    });
    if (this.$store.state.token == "si") {
      this.$connectionService.makeapplogin(
        this.$store.state.usuario,
        this.$store.state.pass
      );
      this.funcionnormal();
    } else {
      this.$router.push({ name: "login" });
    }
  },
  destroyed() {
    this.$connectionService.stop();
  },
  methods: {
    funcionnormal() {
      this.$connectionService.getLista(
        this.$store.state.basedatos,
        this.$store.state.usuario
      );
    },
    refresh(values) {
      this.items = values;
    },
    check(id_mex, value) {
      this.$connectionService.check(
        this.$store.state.basedatos,
        this.$store.state.usuario,
        id_mex,
        value
      );
    },
  },
};
</script>