import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    usuario: null,
    pass: null,
    nombre: null,
    empresa: null,
    basedatos: null,
    tipo: null,
    token: null,    
    mapzoom: 0,
    maplist: "",
    mapindex: "",
    mapitem: {},
    mapitems: []
  },
  getters: {
  },
  mutations: {
    setUsuario(state, usuario) {
      state.usuario = usuario
    },
    setPass(state, pass) {
      state.pass = pass
    },
    setNombre(state, nombre) {
      state.nombre = nombre
    },
    setEmpresa(state, empresa) {
      state.empresa = empresa
    },
    setBasedatos(state, basedatos) {
      state.basedatos = basedatos
    },
    setTipo(state, tipo) {
      state.tipo = tipo
    },
    setToken(state, token) {
      state.token = token
    },
  },
  actions: {
    guardarUsuario({ commit }, usuario) {
      commit("setUsuario", usuario)
      localStorage.setItem("usuario", usuario)
    },
    guardarPass({ commit }, pass) {
      commit("setPass", pass)
      localStorage.setItem("pass", pass)
    },
    guardarNombre({ commit }, nombre) {
      commit("setNombre", nombre)
      localStorage.setItem("nombre", nombre)
    },
    guardarEmpresa({ commit }, empresa) {
      commit("setEmpresa", empresa)
      localStorage.setItem("empresa", empresa)
    },
    guardarBasedatos({ commit }, basedatos) {
      commit("setBasedatos", basedatos)
      localStorage.setItem("basedatos", basedatos)
    },
    guardarTipo({ commit }, tipo) {
      commit("setTipo", tipo)
      localStorage.setItem("tipo", tipo)
    },
    guardarToken({ commit }, token) {
      commit("setToken", token)
      localStorage.setItem("token", token)
    },
  },
  modules: {
  }
})

