import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'
import ListView from '../views/ListView.vue'
import UserView from '../views/UserView.vue'
import HistoryView from '../views/HistoryView.vue'
import WebSystemView from '../views/WebSystemView.vue'
import MapView from '../views/MapView.vue'
import TestView from '../views/TestView.vue'
import MedicalView from '../views/MedicalView.vue'
import PruebaView from '../views/PruebaView.vue'
import PruebaView2 from '../views/PruebaView2.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/list',
    name: 'list',
    component: ListView
  },
  {
    path: '/user',
    name: 'user',
    component: UserView
  },
  {
    path: '/history',
    name: 'history',
    component: HistoryView
  },
  {
    path: '/system',
    name: 'system',
    component: WebSystemView
  },
  {
    path: '/map',
    name: 'map',
    component: MapView
  },
  {
    path: '/test',
    name: 'test',
    component: TestView
  },
  {
    path: '/medical',
    name: 'medical',
    component: MedicalView
  },
  {
    path: '/prueba',
    name: 'prueba',
    component: PruebaView
  },
  {
    path: '/prueba2',
    name: 'prueba2',
    component: PruebaView2
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
