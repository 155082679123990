<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      sort-by="id"
      class="elevation-1"
      :footer-props="{ itemsPerPageText: 'Filas por página:' }"
      :items-per-page="-1"
    >
      <template v-slot:top>
        <v-card-title>
          <v-toolbar dense flat>
            <v-btn small icon>
              <v-icon>mdi-history</v-icon>
            </v-btn>
            <v-toolbar-title>Historial</v-toolbar-title>
            <v-divider class="mx-4" vertical></v-divider>
            <v-menu
              v-model="menuDateBuscaA"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedDateFormattedMomentjsA"
                  label="Fecha de Busqueda Inicial: "
                  readonly
                  v-bind="attrs"
                  hide-details
                  v-on="on"
                  locale="es"
                  class="tam"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es"
                v-model="fecha1"
                @change="funfecha1"
              ></v-date-picker>
            </v-menu>
            <v-divider class="mx-4" vertical></v-divider>
            <v-checkbox v-model="check"></v-checkbox>
            <v-menu
              v-model="menuDateBuscaB"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedDateFormattedMomentjsB"
                  label="Fecha de Busqueda Final: "
                  readonly
                  v-bind="attrs"
                  hide-details
                  v-on="on"
                  locale="es"
                  :disabled="!check"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es"
                v-model="fecha2"
                @change="funfecha2"
              ></v-date-picker>
            </v-menu>
          </v-toolbar>
        </v-card-title>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        {{ items.indexOf(item) + 1 }}
      </template>
      <template v-slot:no-data>
        <br />
        <p>Sin datos disponibles</p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
export default {
  data() {
    return {
      menuDateBuscaA: false,
      menuDateBuscaB: false,
      fecha1: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      fecha2: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      check: false,
      headers: [
        { text: "Id", value: "id", align: "center" },
        { text: "Usuario", value: "usuario", align: "center" },
        { text: "Accion", value: "accion", align: "center" },
        { text: "Fecha", value: "datos", align: "center" },
      ],
      items: [],
    };
  },
  created() {
    this.$connectionService.start();
    Vue.$connectionService.events.on("gethistorial", (values) => {
      this.refresh(values);
    });
    if (this.$store.state.tipo != "Admin") {
      this.$router.push({ name: "home" });
    } else if (this.$store.state.token == "si") {
      this.$connectionService.makeapplogin(
        this.$store.state.usuario,
        this.$store.state.pass
      );
      this.funcionnormal();
    } else {
      this.$router.push({ name: "login" });
    }
    const localLocale1 = moment(this.fecha1).format("DD MMM YYYY");
    moment.locale("es");
    const localLocale2 = moment(this.fecha2).format("DD MMM YYYY");
    moment.locale("es");
  },
  destroyed() {
    this.$connectionService.stop();
  },
  computed: {
    computedDateFormattedMomentjsA() {
      var localLocale = moment(this.fecha1).format("DD MMM YYYY");
      moment.locale("es");
      return this.fecha1 ? localLocale : "";
    },
    computedDateFormattedMomentjsB() {
      var localLocale = moment(this.fecha2).format("DD MMM YYYY");
      moment.locale("es");
      return this.fecha2 ? localLocale : "";
    },
  },
  watch: {
    check(val) {
      val || this.closef();
    },
  },
  methods: {
    funcionnormal() {
      this.$connectionService.getHistorial(
        this.$store.state.basedatos,
        this.fecha1,
        this.fecha2
      );
    },
    refresh(values) {
      this.items = [];
      this.items = values;
    },
    funfecha1() {
      this.menuDateBuscaA = false;
      if (!this.check) {
        this.fecha2 = this.fecha1;
      }
      var a = this.fecha1;
      var b = this.fecha2;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      let datf = new Date(
        b.substr(0, 4),
        (parseInt(b.substr(5, 2)) - 1).toString(),
        b.substr(8, 2)
      );
      let hoy = new Date();
      if (hoy < dati) {
        this.dialogA = true;
        this.fecha1 = format(parseISO(new Date().toISOString()), "yyyy-MM-dd");
        this.fecha2 = this.fecha1;
      } else if (dati > datf) {
        this.dialogB = true;
        const newDate = new Date(dati.setDate(dati.getDate()));
        this.fecha2 = format(parseISO(newDate.toISOString()), "yyyy-MM-dd");
      }
      this.funcionnormal();
    },

    funfecha2() {
      this.menuDateBuscaB = false;
      var a = this.fecha1;
      var b = this.fecha2;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      let datf = new Date(
        b.substr(0, 4),
        (parseInt(b.substr(5, 2)) - 1).toString(),
        b.substr(8, 2)
      );
      let hoy = new Date();
      if (hoy < datf) {
        this.dialogA = true;
        const newDate = new Date(dati.setDate(dati.getDate()));
        this.fecha2 = format(parseISO(newDate.toISOString()), "yyyy-MM-dd");
      } else if (dati > datf) {
        this.dialogB = true;
        const newDate = new Date(dati.setDate(dati.getDate()));
        this.fecha2 = format(parseISO(newDate.toISOString()), "yyyy-MM-dd");
      }
      this.funcionnormal();
    },
    closef() {
      var a = this.fecha1;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      var newDate = new Date(dati.setDate(dati.getDate()));
      this.fecha2 = format(parseISO(newDate.toISOString()), "yyyy-MM-dd");
      this.funcionnormal();
    },
  },
};
</script>