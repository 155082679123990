<template dense>
    <v-card>
        <!-- Pantalla de carga -->
        <v-overlay :value="loading" :opacity="1" style="border-radius: 0px;" color="#009975">
            <v-card outlined align="center"
                style="background-color: white; border-width: 0px; border-radius: 50px; border-color: #009975; width: 300px">
                <v-row>
                    <v-container dense align="center" justify="center">
                        <v-img src="@/assets/logo_contago.png" :width="100"></v-img>
                        <h1 style="color: #009975; font-family: 'Sofia Sans', sant-serif;">Contago Medical</h1>
                        <v-col cols="10">
                            <v-progress-circular indeterminate color="#009975" :size="90" :width="10"></v-progress-circular>
                            <h2 style="color: #009975; font-family: 'Sofia Sans', sant-serif;">Cargando</h2>
                        </v-col>
                        <br>
                    </v-container>
                </v-row>
                <!-- <v-progress-circular indeterminate color="#009975"></v-progress-circular> -->
            </v-card>
        </v-overlay>
        <!-- Imagen de fondo -->
        <!-- Transición para v-if y v-else -->
        <!-- <transition name="fade"> -->
        <!-- <v-card v-if="loaded" dense class="fondo" height="100vh"> -->
        <v-card dense class="fondo" height="100vh">
            <v-col dense>
                <!-- Titulo asistencia médica con imagen -->
                <v-row dense>
                    <v-col cols="12" align="center" justify="center">
                        <v-card outlined style="width: 250px; border-width: 5px; border-radius: 30px; border-color: #009975"
                            align="center" justify="center">
                            <v-img :height="90" :width="90" src="@/assets/doctor_pensando.jpg"></v-img>
                            <h2 style="color: #009975; font-family: 'Sofia Sans', sant-serif;">Asistente Médico</h2>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col dense align="center" justify="center" cols="12">
                        <!-- Descripción de los síntomas -->
                        <v-card outlined dense class="mx-auto my-5" max-width="650"
                            style="border-width: 5px; border-radius: 30px; border-color: #009975">
                            <h2 style="color: #009975; font-family: 'Sofia Sans', sans-serif; font-size: x-large">
                                Descripción de los síntomas</h2>
                            <v-row dense align="center" justify="center">
                                <!-- Lista de sintomas (versión anterior) -->
                                <!-- <v-list dense two-line v-for="(item, index) in items" :key="index">
                                <v-list-item dense>
                                    <v-text-field color="#009975" :id="'text' + index" dense append-icon="mdi-close"
                                        @keydown.enter="add" v-model="item.text"
                                        :style="{ fontFamily: 'Sofia Sans', fontSize: '20px' }">
                                        <template v-slot:append>
                                            <v-icon tabindex="-1" @click="deleteitem(index)" icon :disabled="should">
                                                mdi-close
                                            </v-icon>
                                        </template></v-text-field>
                                </v-list-item>
                            </v-list> -->
                                <!-- Text-field (versión anterior) -->
                                <!-- <v-list dense two-line v-for="(item, index) in items" :key="index">
                                <v-text-field clearable outlined color="#009975" :id="'text'" dense v-model="item.text"
                                    :style="{ fontFamily: 'Sofia Sans', fontSize: '20px' }" prepend-inner-icon="mdi-pencil">
                                </v-text-field>
                            </v-list> -->
                            </v-row>
                            <v-col>
                                <v-textarea color="#009975" no-resize rows="2" v-model="sintomas" dense outlined
                                    style="width: 950px; fontFamily: 'Sofia Sans'; fontSize: 20px;">
                                </v-textarea>
                                <!-- Entrada de texto con un text-field (version anterior) -->
                                <!-- <v-text-field clearable outlined color="#009975" dense v-model="sintomas"
                                :style="{ fontFamily: 'Sofia Sans', fontSize: '20px' }">
                            </v-text-field> -->
                            </v-col>
                            <v-row dense align="center" justify="center">
                                <v-spacer></v-spacer>
                                <v-col>
                                    <v-btn outlined color="#009975" large @click="dialogconfirmar = true"
                                        :disabled="!sintomas">
                                        Analizar
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn color="#009975" large @click="dialogrefresh = true" icon>
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <!-- Botón para borrar un elemento de la lista (versión anterior) -->
                            <!-- <v-col dense align="center" justify="center" cols="5">
                                    <v-btn x-large icon @click="add" :disabled="ret">
                                        <v-icon x-large>mdi-plus-circle-outline</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col dense align="center" justify="center" cols="6">
                                    <v-btn outlined color="#009975" x-large @click="dialogconfirmar = true" :disabled="ret">
                                        Analizar
                                    </v-btn>
                                </v-col> -->
                            <v-dialog persistent v-model="dialogconfirmar" max-width="500">
                                <v-card>
                                    <v-card-title></v-card-title>
                                    <v-card-text class="text-h6">
                                        <!-- <h5>¿Desea obtener una recomendación con los siguientes síntomas?</h5> -->
                                        <h5>¿Deseas agregar algún otro síntoma, alergia, enfermedad crónica o información relevante para la consulta de su recomendación médica?</h5>
                                        <br>
                                        <v-textarea color="#009975" no-resize rows="2" v-model="sintomas2" dense outlined
                                            style="width: 950px; fontFamily: 'Sofia Sans'; fontSize: 20px;">
                                        </v-textarea>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn color="#009975" text @click="dialogconfirmar = false">
                                            <h4>Cancelar</h4>
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn color="#009975" text @click="analizar">
                                            <h4>Analizar</h4>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-dialog persistent v-model="dialogrefresh" max-width="350">
                                <v-card>
                                    <v-card-title></v-card-title>
                                    <v-card-text>
                                        <h3>¿Desea actualizar el contenido?</h3>
                                        <h3 class="text-justify">• Recuerde que se va a borrar la recomendación
                                            anterior, pero si desea la puede guardar antes como pdf.</h3>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn color="#009975" text @click="dialogrefresh = false">
                                            <h4>Cancelar</h4>
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn color="#009975" text @click="clear">
                                            <h4>Aceptar</h4>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <br>
                        </v-card>
                    </v-col>
                    <!-- Divider vertical (opcional) -->
                    <!-- <v-divider vertical fill-height
                        style="border-width: 5px; background-color: #009975; height: 90vh;"></v-divider>
                    <div style="width: 10px;"></div> -->
                    <v-col dense align="center" justify="center" cols="12">
                        <!-- Recomendación médica -->
                        <v-card outlined dense max-width="1000"
                            style="border-width: 5px; border-radius: 30px; border-color: #009975">
                            <h2 style="color: #009975; font-family: 'Sofia Sans', sans-serif; font-size: x-large">
                                Recomendación médica</h2>
                            <v-col>
                                <v-textarea color="#009975" no-resize rows="7" v-model="textarea" dense readonly outlined
                                    style="width: 950px; fontFamily: 'Sofia Sans'; fontSize: 20px;"></v-textarea>
                                <v-btn @click="generarPDF" color="#009975" icon><v-icon>mdi-file-pdf-box</v-icon></v-btn>
                            </v-col>
                        </v-card>
                    </v-col>
                    <v-dialog persistent v-model="dialog500" max-width="350">
                        <v-card>
                            <v-card-title></v-card-title>
                            <v-card-text class="text-h6">
                                <h5>Problemas de conexión</h5>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="program" text @click="dialog500 = false">
                                    Aceptar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
                        <v-card color="#009975" dark>
                            <v-card-text>
                                Cargando
                                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                    <v-dialog persistent v-model="dialog404" max-width="350">
                        <v-card>
                            <v-card-title class="headline"> Error </v-card-title>
                            <v-card-text class="text-h6">
                                <h5>{{ Message }}</h5>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="program" text @click="dialog404 = false">
                                    Aceptar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </v-col>
        </v-card>
        <!-- Pantalla de carga (con v-if y v-else)-->
        <!-- <v-card v-else height="100vh" align="center" justify="center" outlined
                style="border-width: 20px; border-radius: 0px; border-color: #009975">
                <br>
                <br>
                <br>
                <br>
                <br>
                <v-row>
                    <v-container dense outlined align="center" justify="center">
                        <v-img src="@/assets/logo_contago.png" :width="100"></v-img>
                        <h1 style="color: #009975; font-family: 'Sofia Sans', sant-serif;">Contago Medical</h1>
                        <br>
                        <br>
                        <br> -->
        <!-- Progress circular -->
        <!-- <v-col cols="10">
                            <v-progress-circular indeterminate color="#009975" :size="90" :width="10"></v-progress-circular>
                            <h2 style="color: #009975; font-family: 'Sofia Sans', sant-serif;">Cargando</h2>
                        </v-col> -->
        <!-- Progress Linear -->
        <!-- <v-col cols="10">
                            <v-progress-linear color="#009975" indeterminate rounded height="10"></v-progress-linear>
                            <h2 style="color: #009975; font-family: 'Sofia Sans', sant-serif;">Cargando...</h2>
                        </v-col> -->
        <!-- </v-container>
                </v-row>
            </v-card> -->
        <!-- </transition> -->
    </v-card>
</template>

<style scoped>
.hidden-icon {
    display: none;
}

.fondo {
    background: url('../assets/fondo.png');
    background-size: 400px;
    /* Prueba webp */
    /* background-image: url('../assets/fondo.webp');
    background-repeat: repeat; */
    /* Otras opciones para el fondo */
    /* background-repeat: repeat; */
    /* height: 100vh; */
    /* background-attachment: fixed; */
    /* transition: "fade-transition"; */
}

/* Transición para v-if y v-else */
/* .fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
} */
.v-overlay__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.v-progress-circular {
    margin: 1rem;
}
</style>

<script>
import Vue from "vue";
import axios from "axios";
import { jsPDF } from 'jspdf';
// import cornerImage from '@/assets/doctor_pensando.jpg';
import cornerImage from '@/assets/logo_contago.png';

export default {
    data() {
        return {
            Message: "Internal Error",
            sintomas: "",
            sintomas2: "",
            // textarea: "Posibles diagnósticos:\n\n1. Gripa común\n- Tratamiento: Descanso, hidratación adecuada, analgésicos para el dolor y fiebre, como acetaminofén o ibuprofeno.\n2. Sinusitis\n- Tratamiento: Descongestionantes nasales, analgésicos para el dolor y fiebre, como acetaminofén o ibuprofeno, y en casos severos, antibióticos.\n3. Migraña\n- Tratamiento: Analgésicos específicos para migraña, evitar factores desencadenantes como luces brillantes o ruido fuerte, reposo en un ambiente tranquilo y oscuro.\n4. Dengue\n- Tratamiento: Descanso, hidratación adecuada, analgésicos para el dolor y fiebre, como acetaminofén o ibuprofeno. En algunos casos, puede ser necesaria la hospitalización.\n\nEs importante recordar que estos son solo posibles diagnósticos y tratamientos y que siempre es recomendable acudir a un médico para una evaluación adecuada y un diagnóstico preciso.",
            textarea: "",
            title: 'Recomendación Médica',
            dialogconfirmar: false,
            dialogrefresh: false,
            dialog404: false,
            dialog500: false,
            dialogIni: false,
            items: [
                { text: "" }
            ],
            // loaded: false,
            loading: true,
            interval: {},
            value: 0,
        };
    },
    computed: {
        ret() {
            if (this.items.length > 0) {
                if (this.items[this.items.length - 1].text == "") {
                    return true
                }
                else {
                    return false
                }
            }
            else {
                return false
            }
        },
        should() {
            if (this.items.length == 1) {
                return true
            }
            else {
                return false
            }
        }
    },
    mounted() {
        // Pantalla de Carga (con v-if y v-else)
        // setTimeout(() => {
        //     this.loaded = true;
        // }, 3000);
        // Pantalla de Carga (con overlay)
        setTimeout(() => {
            this.loading = false;
        }, 3000);
    },
    methods: {
        add() {
            if (!this.ret) {
                this.items.push({ text: "" });
                setTimeout(() => {
                    let num = this.items.length - 1;
                    document.getElementById("text" + num).focus();
                }, 0);
            }
        },
        deleteitem(index) {
            if (this.items.length > 1) {
                this.items.splice(index, 1);
            }
        },
        generarPDF() {
            const pdf = new jsPDF();

            pdf.setFont('Sofia Sans', 'normal');
            pdf.setFontSize(18);

            pdf.text(this.title, pdf.internal.pageSize.getWidth() / 2, 20, { align: 'center' });


            const cornerImageObj = new Image();
            cornerImageObj.src = cornerImage;
            cornerImageObj.onload = () => {
                // Imagen en la esquina superior izquierda
                // pdf.addImage(cornerImageObj, 'PNG', 10, 15, 50, 50)
                pdf.addImage(cornerImageObj, 'PNG', 170, 10, 30, 30)

                pdf.setFont('Sofia Sans', 'normal');

                const margin = 10;
                const contentWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
                const contentHeight = pdf.internal.pageSize.getHeight() - 2 * margin;

                pdf.rect(margin, margin, contentWidth, contentHeight);

                const lines = pdf.splitTextToSize(this.textarea, pdf.internal.pageSize.getWidth() - 40);

                pdf.text(margin + 10, margin + 20, lines);

                // Ver el pdf en una ventana nueva
                // window.open(pdf.output('bloburl'), '_blank');

                pdf.save('Recomendación_Médica.pdf')
            };
        },
        clear() {
            // this.items[0].text = '';
            this.sintomas = '';
            this.sintomas2 = '';
            this.textarea = '';
            this.dialogrefresh = false;
        },
        refresh() {
            window.location.reload();
        },
        analizar() {
            if (!this.sintomas) {
                return;
            }
            this.dialogIni = true;
            var Params = {
                model: "gpt-3.5-turbo",
                messages: [
                    {
                        role: "user",
                        content: "Dame el diagnostico medico de los siguientes sintomas:" + this.sintomas && this.sintomas2 + "\nDame la respuesta solamente con los posibles diagnosticos y tratamientos sin destacar ningun otro aspecto. Al tener la respuesta final, omite el primer y ultimo parrafo."
                    }
                ]
            };
            console.log(Params);
            axios
                .post("https://api.openai.com/v1/chat/completions", Params)
                .then((response) => {
                    console.log(response);
                    this.dialogIni = false;
                    this.textarea = response.data.choices[0].message.content;
                    this.dialogconfirmar = false;
                    this.sintomas += '.' + '\n' + 'Otros: ' + this.sintomas2;
                    this.sintomas2 = '';
                })
                .catch((error) => {
                    console.log(error);
                    this.dialogIni = false;
                    this.dialogconfirmar = false;
                    if (error.response) {
                        if (error.response.status == 400) {
                            this.Message = error.response.data;
                            this.dialog404 = true;
                        } else {
                            this.dialog500 = true;
                        }
                    } else {
                        this.dialog500 = true;
                    }
                })
        }
        // Funcion para el diagnóstico con lista
        // analizar() {
        //     if (this.items.length > 0) {
        //         this.dialogIni = true
        //         var lista = "";
        //         for (var i = 0; i < this.items.length; i++) {
        //             lista = lista + "\n- " + this.items[i].text;
        //         }
        //         var Params = {
        //             model: "gpt-3.5-turbo",
        //             messages: [
        //                 {
        //                     role: "user",
        //                     content: "Dame el diagnostico medico de los siguientes sintomas:" + lista + "\nDame la respuesta solamente con los posibles diagnosticos y tratamientos sin destacar ningun otro aspecto. Al tener la respuesta final, omite el primer y ultimo parrafo."
        //                 }
        //             ]
        //         };
        //         console.log(Params);
        //         axios
        //             .post("https://api.openai.com/v1/chat/completions", Params)
        //             .then((response) => {
        //                 console.log(response);
        //                 this.dialogIni = false;
        //                 this.textarea = response.data.choices[0].message.content;
        //                 this.dialogconfirmar = false;
        //             })
        //             .catch((error) => {
        //                 console.log(error);
        //                 this.dialogIni = false;
        //                 this.dialogconfirmar = false;
        //                 if (error.response) {
        //                     if (error.response.status == 400) {
        //                         this.Message = error.response.data;
        //                         this.dialog404 = true;
        //                     } else {
        //                         this.dialog500 = true;
        //                     }
        //                 } else {
        //                     this.dialog500 = true;
        //                 }
        //             })
        //     }
        // }
    },
};
</script>