<template>
  <v-row>
    <v-col>
      <br />
      <v-row>
        <v-btn large dark color="program" text @click="funcreate">
          <v-icon dark small color="program">mdi-account-plus</v-icon>
          <pre>&nbsp;</pre>
          Crear Cuenta
        </v-btn>
        <v-spacer />
        <v-btn large dark color="program" text @click="contago">
          <v-icon dark small color="program">mdi-phone</v-icon>
          <pre>&nbsp;</pre>
          Contactanos
        </v-btn>
      </v-row>
      <v-card class="mx-auto my-12" max-width="400">
        <v-row>
          <v-spacer></v-spacer>
          <v-img src="@/assets/contago.png" max-width="400" />
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-col cols="2"> </v-col>
          <v-col cols="8">
            <v-form ref="form1" onSubmit="return false;">
              <v-text-field
                v-model="datos.user"
                label="Usuario"
                outlined
                small
                color="program"
                :rules="[rules.required]"
                dense
                @keydown.enter="login"
              ></v-text-field>
            </v-form>
            <v-form ref="form2" onSubmit="return false;">
              <v-text-field
                v-model="datos.pass"
                type="password"
                label="Contraseña"
                outlined
                small
                color="program"
                :rules="[rules.required]"
                dense
                @keydown.enter="login"
              ></v-text-field>
            </v-form>
            <v-row><v-col></v-col></v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn id="g1" large dark color="program" text @click="login">
                Iniciar Sesión
              </v-btn>
              <v-spacer></v-spacer>
            </v-row>
            <v-row><v-col></v-col></v-row>
          </v-col>
          <v-col cols="2"> </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import Vue from "vue";
export default {
  data: () => ({
    datos: {
      user: "",
      pass: "",
    },
    rules: {
      required: (value) => !!value || "Requerido",
    },
  }),

  created() {
    if (this.$store.state.token == "si") {
      this.$store.dispatch("guardarToken", "no");
      this.$connectionService.start();
      this.$connectionService.makelogin(
        this.$store.state.usuario,
        this.$store.state.pass
      );
    }
  },

  methods: {
    login() {
      var a = this.$refs.form1.validate();
      var b = this.$refs.form2.validate();
      if (a & b) {
        this.$connectionService.login();
        this.$connectionService.start();
        this.$connectionService.makelogin(this.datos.user, this.datos.pass);
      }
    },
    funcreate() {
      console.log("create account");
    },
    contago() {
      window.open("https://contago.com.mx/");
    },
  },
};
</script>