<template>
    <v-card height="100vh">
        <!-- Pantalla de carga -->
        <v-overlay :value="loading" :opacity="1" style="border-radius: 0px;" color="white">
            <v-icon class="rotate-animation" size="100px" color="#009975">{{ randomIcon }}</v-icon>
        </v-overlay>
        <v-card-title align="center" justify="center">
            <v-col align="center" justify="center">
                Consultas
            </v-col>
        </v-card-title>
        <v-card-text align="center" justify="center">
            <v-col>
                Prueba
            </v-col>
        </v-card-text>
        <v-card-actions>

        </v-card-actions>
    </v-card>
</template>
<style scoped>
.rotate-animation {
    animation: rotate 2s infinite linear ;
    /* animation-delay: 400ms; */
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
<script>
export default {
    data: () => ({
        Message: "Internal Error",
        randomIcon: "",
        iconos: ["mdi-pill", "mdi-medical-bag", "mdi-stethoscope", "mdi-needle", "mdi-doctor", "mdi-bottle-tonic-plus", "mdi-hospital-building", "mdi-ambulance", "mdi-radiology-box-outline"],
        loading: true,
        dialogIni: false,
        dialog404: false,
        dialog500: false,
    }),
    computed: {
    },
    watch: {
    },
    mounted() {
        this.mostrarIcono();

        // Tiempo de la pantalla de carga (versión anterior)
        // setTimeout(() => {
        //     this.loading = false;
        // }, 2500);
    },
    methods: {
        // Función para mostrar icono al azar
        mostrarIcono() {
            this.randomIcon = this.iconos[Math.floor(Math.random() * this.iconos.length)];
            this.loading = true;
            var a = this.dialogIni;
            // Tiempo de la pantalla de carga
            setTimeout(() => {
                this.loading = false;
            }, 2000);
        },
    },
};
</script>