<template>
  <v-app>
    <v-card class="mx-auto overflow-hidden" tile height="100%" width="100%">
      <template v-if="this.$route.name != 'test' && this.$route.name != 'medical' && this.$route.name != 'prueba' && this.$route.name != 'prueba2'">
        <v-app-bar dense color="program" dark>
          <template v-if="$store.state.token == 'si'">
            <v-col cols="12">
              <v-row>
                <v-badge :content="messages" :value="messages" color="warning" overlap bottom>
                  <v-app-bar-nav-icon dense title="Menú" @click.stop="drawer = !drawer">
                    <v-img src="@/assets/logo_contago.png" transition="scale-transition" max-height="50" max-width="50" />
                  </v-app-bar-nav-icon>
                </v-badge>
                <v-spacer style="display: flex; min-width: 1%" />
                <v-btn title="Cerrar Sesión" @click="dialog = true" icon>
                  <v-icon large>mdi-logout-variant</v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </template>
        </v-app-bar>
      </template>
      <v-navigation-drawer v-model="drawer" absolute temporary app clipped>
        <v-list>
          <template>
            <v-list-item :to="{ path: '/home' }">
              <v-list-item-action>
                <v-icon>mdi-chart-bar</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Inicio</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ path: '/list' }">
              <v-list-item-action>
                <v-icon>mdi-clipboard-list-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Lista</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-if="$store.state.tipo == 'Admin'">
              <v-list-item :to="{ path: '/map' }">
                <v-list-item-action>
                  <v-icon>mdi-map-marker-radius-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Mapa</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ path: '/user' }">
                <v-list-item-action>
                  <v-icon>mdi-account-group</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Usuarios</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{ path: '/history' }">
                <v-list-item-action>
                  <v-icon>mdi-history</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Historial</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </template>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <v-container fluid style="padding: 0px; margin: 0px;">
          <v-fade-transition mode="out-in">
            <router-view></router-view>
          </v-fade-transition>
        </v-container>
      </v-main>
      <!--nav>
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
      </nav>
      <router-view /-->
    </v-card>
    <v-dialog persistent v-model="dialog" max-width="350">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text class="text-h6">
          <h5>¿Desea cerrar la sesión?</h5>
        </v-card-text>
        <v-card-actions>
          <v-btn color="program" text @click="dialog = false">
            <h4>Cancelar</h4>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="program" text @click="appsalir">
            <h4>Aceptar</h4>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Iniciando Sesión
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialog500" max-width="350">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-h6">
          <h5>Problemas de conexión</h5>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="program" text @click="dialog500 = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialog404" max-width="350">
      <v-card>
        <v-card-title class="headline"> Error </v-card-title>
        <v-card-text class="text-h6">
          <h5>{{ Message }}</h5>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="program" text @click="dialog404 = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<style>
.to {
  display: none;
}
</style>

<script>
import Vue from "vue";
export default {
  name: "App",

  data: () => ({
    Message: "Internal Error",
    dialogIni: false,
    dialog500: false,
    dialog404: false,
    dialog: false,
    drawer: false,
    group: null,
    messages: 0,
  }),
  created() {
    console.log(this.$route.name);
    Vue.$connectionService.events.on("ini", () => {
      this.dialogIni = true;
    });
    Vue.$connectionService.events.on("applogin", (values) => {
      if (values.token == "si") {
        this.$store.dispatch("guardarUsuario", values.usuario);
        this.$store.dispatch("guardarPass", values.pass);
        this.$store.dispatch("guardarNombre", values.nombre);
        this.$store.dispatch("guardarEmpresa", values.empresa);
        this.$store.dispatch("guardarBasedatos", values.basedatos);
        this.$store.dispatch("guardarTipo", values.tipo);
        this.$store.dispatch("guardarToken", values.token);
      } else {
        this.$connectionService.stop();
        this.$store.dispatch("guardarUsuario", "");
        this.$store.dispatch("guardarPass", "");
        this.$store.dispatch("guardarNombre", "");
        this.$store.dispatch("guardarEmpresa", "");
        this.$store.dispatch("guardarBasedatos", "");
        this.$store.dispatch("guardarTipo", "");
        this.$store.dispatch("guardarToken", "no");
        this.$router.push({ name: "login" });
      }
    });
    Vue.$connectionService.events.on("login", (values) => {
      this.dialogIni = false;
      this.$connectionService.stop();
      if (values.token == "si") {
        this.$store.dispatch("guardarUsuario", values.usuario);
        this.$store.dispatch("guardarPass", values.pass);
        this.$store.dispatch("guardarNombre", values.nombre);
        this.$store.dispatch("guardarEmpresa", values.empresa);
        this.$store.dispatch("guardarBasedatos", values.basedatos);
        this.$store.dispatch("guardarTipo", values.tipo);
        this.$store.dispatch("guardarToken", values.token);
        this.$router.push({ name: "home" });
      } else {
        this.$store.dispatch("guardarToken", values.token);
        this.Message = "Usuario o Contraseña incorrecta. Vuelve a intentarlo.";
        this.dialog404 = true;
      }
    });
  },
  methods: {
    appsalir() {
      this.dialog = false;
      this.$connectionService.stop();
      this.$store.dispatch("guardarUsuario", "");
      this.$store.dispatch("guardarPass", "");
      this.$store.dispatch("guardarNombre", "");
      this.$store.dispatch("guardarEmpresa", "");
      this.$store.dispatch("guardarBasedatos", "");
      this.$store.dispatch("guardarTipo", "");
      this.$store.dispatch("guardarToken", "no");
      this.$router.push({ name: "login" });
    },
  },

  /*
  Error AXIOS
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  */
};
</script>