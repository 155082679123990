<template>
  <div>
    <v-card dense class="elevation-1">
      <v-card-title>
        <v-toolbar dense flat>
          <v-btn icon small @click="menu = true">
            <v-icon>mdi-map-marker-radius-outline</v-icon>
          </v-btn>
          <v-toolbar-title>Mapa</v-toolbar-title>
          <v-menu v-model="menu" dense bottom left offset-y>
            <template v-slot:activator="{}">
              <v-divider class="mx-4" vertical></v-divider>
            </template>
            <v-list dense style="max-height: 330px" class="overflow-y-auto">
              <v-list-item
                dense
                @click="$store.state.maplist = item.id"
                v-for="(item, i) in $store.state.mapitems"
                :key="i"
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer>
            <v-btn
              block
              @click="atras"
              :disabled="index == principal"
              class="white--text"
              color="program"
              ><v-icon>mdi-arrow-left-drop-circle-outline</v-icon
              >&nbsp;Atras</v-btn
            ></v-spacer
          >
          <v-divider class="mx-4" vertical></v-divider>
          <v-spacer>
            <v-btn
              block
              @click="ir"
              :disabled="$store.state.mapindex == index || index != principal"
              class="white--text"
              color="program"
              >Abrir&nbsp;<v-icon
                >mdi-arrow-right-drop-circle-outline</v-icon
              ></v-btn
            >
          </v-spacer>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <template v-if="index == 'aguascalientes'">
          <AguascalientesMap></AguascalientesMap>
        </template>
        <template v-else-if="index == 'bajacalifornia'">
          <BajaCaliforniaMap></BajaCaliforniaMap>
        </template>
        <template v-else-if="index == 'bajacaliforniasur'">
          <BajaCaliforniaSurMap></BajaCaliforniaSurMap>
        </template>
        <template v-else-if="index == 'campeche'">
          <CampecheMap></CampecheMap>
        </template>
        <template v-else-if="index == 'chiapas'">
          <ChiapasMap></ChiapasMap>
        </template>
        <template v-else-if="index == 'chihuahua'">
          <ChihuahuaMap></ChihuahuaMap>
        </template>
        <template v-else-if="index == 'ciudaddemexico'">
          <CiudaddeMexicoMap></CiudaddeMexicoMap>
        </template>
        <template v-else-if="index == 'coahuila'">
          <CoahuilaMap></CoahuilaMap>
        </template>
        <template v-else-if="index == 'colima'">
          <ColimaMap></ColimaMap>
        </template>
        <template v-else-if="index == 'durango'">
          <DurangoMap></DurangoMap>
        </template>
        <template v-else-if="index == 'guanajuato'">
          <GuanajuatoMap></GuanajuatoMap>
        </template>
        <template v-else-if="index == 'guerrero'">
          <GuerreroMap></GuerreroMap>
        </template>
        <template v-else-if="index == 'hidalgo'">
          <HidalgoMap></HidalgoMap>
        </template>
        <template v-else-if="index == 'jalisco'">
          <JaliscoMap></JaliscoMap>
        </template>
        <template v-else-if="index == 'mexico'">
          <MexicoMap></MexicoMap>
        </template>
        <template v-else-if="index == 'michoacan'">
          <MichoacanMap></MichoacanMap>
        </template>
        <template v-else-if="index == 'morelos'">
          <MorelosMap></MorelosMap>
        </template>
        <template v-else-if="index == 'nayarit'">
          <NayaritMap></NayaritMap>
        </template>
        <template v-else-if="index == 'nuevoleon'">
          <NuevoLeonMap></NuevoLeonMap>
        </template>
        <template v-else-if="index == 'oaxaca'">
          <OaxacaMap></OaxacaMap>
        </template>
        <template v-else-if="index == 'puebla'">
          <PueblaMap></PueblaMap>
        </template>
        <template v-else-if="index == 'queretaro'">
          <QueretaroMap></QueretaroMap>
        </template>
        <template v-else-if="index == 'quintanaroo'">
          <QuintanaRooMap></QuintanaRooMap>
        </template>
        <template v-else-if="index == 'sanluispotosi'">
          <SanLuisPotosiMap></SanLuisPotosiMap>
        </template>
        <template v-else-if="index == 'sinaloa'">
          <SinaloaMap></SinaloaMap>
        </template>
        <template v-else-if="index == 'sonora'">
          <SonoraMap></SonoraMap>
        </template>
        <template v-else-if="index == 'tabasco'">
          <TabascoMap></TabascoMap>
        </template>
        <template v-else-if="index == 'tamaulipas'">
          <TamaulipasMap></TamaulipasMap>
        </template>
        <template v-else-if="index == 'tlaxcala'">
          <TlaxcalaMap></TlaxcalaMap>
        </template>
        <template v-else-if="index == 'veracruz'">
          <VeracruzMap></VeracruzMap>
        </template>
        <template v-else-if="index == 'yucatan'">
          <YucatanMap></YucatanMap>
        </template>
        <template v-else-if="index == 'zacatecas'">
          <ZacatecasMap></ZacatecasMap>
        </template>
        <template v-else-if="index == 'mx'">
          <PaisMexicoMap></PaisMexicoMap>
        </template>
        <template v-else>
          <h1>Actualizar Página</h1>
        </template>
        <v-row align="center" justify="center">
          <v-col
            align="center"
            justify="center"
            cols="10"
            sm="6"
            md="6"
            lg="4"
            xl="4"
          >
            <v-row align="center" justify="center">
              <v-btn @click="dialoglist = true" small icon
                ><v-icon title="Lista">mdi-format-list-bulleted</v-icon></v-btn
              >
              <v-icon color="white">mdi-circle-small</v-icon>
              <v-btn
                small
                icon
                :disabled="$store.state.mapzoom <= 0"
                @click="clickminus"
                ><v-icon title="Zoom">mdi-minus</v-icon></v-btn
              >
              <v-slider
                dense
                hide-details
                :max="2"
                min="0"
                v-model="$store.state.mapzoom"
                persistent-hint
                inverse-label
                readonly
                horizontal
              ></v-slider>
              <v-btn
                small
                icon
                :disabled="$store.state.mapzoom >= 2"
                @click="clickplus"
                ><v-icon title="Zoom">mdi-plus</v-icon></v-btn
              >
              <v-icon color="white">mdi-circle-small</v-icon>
              <v-btn @click="dialogchart = true" small icon
                ><v-icon title="Gráfica">mdi-chart-bar</v-icon></v-btn
              >
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog persistent v-model="dialoglist" max-width="750">
      <v-card dense>
        <v-card-title>
          <h5>Lista de {{ titulochart }}</h5>
          <v-spacer> </v-spacer>
          <v-btn @click="dialoglist = false" small icon
            ><v-icon title="Cerrar">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col align="center" justify="center" cols="12">
              <v-toolbar dense flat>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>
              <v-layout column style="height: 40vh">
                <v-flex style="overflow: auto">
                  <v-data-table
                    dense
                    :headers="headers"
                    :items="items"
                    :search="search"
                    sort-by="id_mex"
                    :footer-props="{ itemsPerPageText: 'Filas por página:' }"
                    :items-per-page="-1"
                    hide-default-footer
                    hide-default-header
                  >
                    <template v-slot:[`item.accion`]="{ item }">
                      <template v-if="item.accion == 'true'">
                        <v-btn icon @click="check(item.id_mex, 'false')">
                          <v-icon color="success">mdi-checkbox-blank</v-icon>
                        </v-btn>
                      </template>
                      <template v-else>
                        <v-btn icon @click="check(item.id_mex, 'true')">
                          <v-icon color="gray"
                            >mdi-checkbox-blank-outline</v-icon
                          >
                        </v-btn>
                      </template>
                    </template>
                    <template v-slot:no-data>
                      <br />
                      <p>Sin datos disponibles</p>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
              <v-row><v-col></v-col></v-row>
              <v-row><v-col></v-col></v-row>
              <span>{{ items.length }}&nbsp; elementos</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogchart" max-width="750">
      <v-card dense>
        <v-card-title>
          <h5>Gráfica de {{ titulochart }}</h5>
          <v-spacer> </v-spacer>
          <v-btn @click="dialogchart = false" small icon
            ><v-icon title="Cerrar">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col align="center" justify="center" cols="12">
              <template v-if="data1.datasets[0].data[2] == '0'"
                ><h1></h1>
                <h1>No hay datos registrados</h1></template
              >
              <Chart
                class="canvaschart"
                block
                :type="typefinal"
                :data="data1"
                :options="options1"
              >
              </Chart>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.canvaschart {
  height: 400px;
  width: auto;
}
</style>

<script>
import Vue from "vue";
import PaisMexicoMap from "../components/Mapas/Mexico/PaisMexicoMap.vue";
import AguascalientesMap from "../components/Mapas/Mexico/Aguascalientes/AguascalientesMap.vue";
import BajaCaliforniaMap from "../components/Mapas/Mexico/BajaCalifornia/BajaCaliforniaMap.vue";
import BajaCaliforniaSurMap from "../components/Mapas/Mexico/BajaCaliforniaSur/BajaCaliforniaSurMap.vue";
import CampecheMap from "../components/Mapas/Mexico/Campeche/CampecheMap.vue";
import ChiapasMap from "../components/Mapas/Mexico/Chiapas/ChiapasMap.vue";
import ChihuahuaMap from "../components/Mapas/Mexico/Chihuahua/ChihuahuaMap.vue";
import CiudaddeMexicoMap from "../components/Mapas/Mexico/CiudaddeMexico/CiudaddeMexicoMap.vue";
import CoahuilaMap from "../components/Mapas/Mexico/Coahuila/CoahuilaMap.vue";
import ColimaMap from "../components/Mapas/Mexico/Colima/ColimaMap.vue";
import DurangoMap from "../components/Mapas/Mexico/Durango/DurangoMap.vue";
import GuanajuatoMap from "../components/Mapas/Mexico/Guanajuato/GuanajuatoMap.vue";
import GuerreroMap from "../components/Mapas/Mexico/Guerrero/GuerreroMap.vue";
import HidalgoMap from "../components/Mapas/Mexico/Hidalgo/HidalgoMap.vue";
import JaliscoMap from "../components/Mapas/Mexico/Jalisco/JaliscoMap.vue";
import MexicoMap from "../components/Mapas/Mexico/Mexico/MexicoMap.vue";
import MichoacanMap from "../components/Mapas/Mexico/Michoacan/MichoacanMap.vue";
import MorelosMap from "../components/Mapas/Mexico/Morelos/MorelosMap.vue";
import NayaritMap from "../components/Mapas/Mexico/Nayarit/NayaritMap.vue";
import NuevoLeonMap from "../components/Mapas/Mexico/NuevoLeon/NuevoLeonMap.vue";
import OaxacaMap from "../components/Mapas/Mexico/Oaxaca/OaxacaMap.vue";
import PueblaMap from "../components/Mapas/Mexico/Puebla/PueblaMap.vue";
import QueretaroMap from "../components/Mapas/Mexico/Queretaro/QueretaroMap.vue";
import QuintanaRooMap from "../components/Mapas/Mexico/QuintanaRoo/QuintanaRooMap.vue";
import SanLuisPotosiMap from "../components/Mapas/Mexico/SanLuisPotosi/SanLuisPotosiMap.vue";
import SinaloaMap from "../components/Mapas/Mexico/Sinaloa/SinaloaMap.vue";
import SonoraMap from "../components/Mapas/Mexico/Sonora/SonoraMap.vue";
import TabascoMap from "../components/Mapas/Mexico/Tabasco/TabascoMap.vue";
import TamaulipasMap from "../components/Mapas/Mexico/Tamaulipas/TamaulipasMap.vue";
import TlaxcalaMap from "../components/Mapas/Mexico/Tlaxcala/TlaxcalaMap.vue";
import VeracruzMap from "../components/Mapas/Mexico/Veracruz/VeracruzMap.vue";
import YucatanMap from "../components/Mapas/Mexico/Yucatan/YucatanMap.vue";
import ZacatecasMap from "../components/Mapas/Mexico/Zacatecas/ZacatecasMap.vue";
import Chart from "primevue/chart";
export default {
  components: {
    PaisMexicoMap,
    AguascalientesMap,
    BajaCaliforniaMap,
    BajaCaliforniaSurMap,
    CampecheMap,
    ChiapasMap,
    ChihuahuaMap,
    CiudaddeMexicoMap,
    CoahuilaMap,
    ColimaMap,
    DurangoMap,
    GuanajuatoMap,
    GuerreroMap,
    HidalgoMap,
    JaliscoMap,
    MexicoMap,
    MichoacanMap,
    MorelosMap,
    NayaritMap,
    NuevoLeonMap,
    OaxacaMap,
    PueblaMap,
    QueretaroMap,
    QuintanaRooMap,
    SanLuisPotosiMap,
    SinaloaMap,
    SonoraMap,
    TabascoMap,
    TamaulipasMap,
    TlaxcalaMap,
    VeracruzMap,
    YucatanMap,
    ZacatecasMap,
    Chart,
  },
  data() {
    return {
      menu: false,
      principal: "mx",
      index: "",
      dialoglist: false,
      dialogchart: false,
      codigo: "",
      data1: {
        labels: ["Registrados", "Faltantes", "Total"],
        datasets: [
          {
            borderColor: ["#43A047", "#FFA726", "#9D2449"],
            backgroundColor: ["#43A047C0", "#FFA726C0", "#9D2449C0"],
            borderWidth: 0,
            borderRadius: 1,
            data: [0, 0, 0],
          },
        ],
      },
      options1: {
        maintainAspectRatio: false,
        animation: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
      headers: [
        { text: "Id", value: "id_mex", align: "center" },
        { text: "Nombre", value: "nombre", align: "center" },
        {
          text: "Registro",
          value: "accion",
          align: "center",
          sortable: false,
        },
      ],
      items: [],
      search: "",
    };
  },
  computed: {
    mapindex1() {
      return this.$store.state.mapindex;
    },
    titulochart() {
      if (
        this.index == this.$store.state.mapindex ||
        this.index == "" ||
        this.$store.state.mapindex == ""
      ) {
        return this.$store.state.mapitem.name;
      } else {
        const obj =
          this.$store.state.mapitems[
            this.$store.state.mapitems.findIndex(
              (a) => a.id == this.$store.state.mapindex
            )
          ];
        return obj.name + ", " + this.$store.state.mapitem.name;
      }
    },
    typefinal() {
      return "bar";
    },
  },
  watch: {
    codigo() {
      this.funcionnormal();
    },
    mapindex1(val) {
      if (val == "") {
        this.codigo = this.index;
      } else {
        this.codigo = val;
      }
    },
  },
  created() {
    this.$connectionService.start();
    Vue.$connectionService.events.on("getgraficapart", (values) => {
      this.refreshgrafica(values);
    });
    Vue.$connectionService.events.on("getlistapart", (values) => {
      this.refreshlista(values);
    });
    Vue.$connectionService.events.on("check", (values) => {
      if (values == "si") {
        this.funcionnormal();
      }
    });
    if (this.$store.state.tipo != "Admin") {
      this.$router.push({ name: "home" });
    } else if (this.$store.state.token == "si") {
      this.$connectionService.makeapplogin(
        this.$store.state.usuario,
        this.$store.state.pass
      );
      this.funcionnormal();
    } else {
      this.$router.push({ name: "login" });
    }
  },
  destroyed() {
    this.$connectionService.stop();
  },
  mounted() {
    this.$store.state.mapzoom = 0;
    this.$store.state.mapindex = this.principal;
    this.index = this.principal;
    this.codigo = this.principal;
  },
  methods: {
    funcionnormal() {
      this.$connectionService.getGraficaPart(
        this.$store.state.basedatos,
        this.codigo
      );
      this.$connectionService.getListaPart(
        this.$store.state.basedatos,
        this.codigo
      );
    },
    refreshgrafica(values) {
      this.data1.datasets[0].data = [
        values.cantidad,
        values.faltante,
        values.total,
      ];
    },
    refreshlista(values) {
      this.items = values;
    },
    atras() {
      this.index = this.principal;
      this.$store.state.mapindex = this.principal;
    },
    ir() {
      this.index = this.$store.state.mapindex;
    },
    clickplus() {
      if (this.$store.state.mapzoom < 2) {
        this.$store.state.mapzoom++;
      }
    },
    clickminus() {
      if (this.$store.state.mapzoom > 0) {
        this.$store.state.mapzoom--;
      }
    },
    check(id_mex, value) {
      this.$connectionService.check(
        this.$store.state.basedatos,
        this.$store.state.usuario,
        id_mex,
        value
      );
    },
  },
};
</script>

<!--
style="([^"]*|\n)*"
<path([^<]*|\n)
<title([^\n]*|\n)
-->
