<template style="margin: 0px; padding: 0px;">
    <v-row dense
        style="font-family: Arial, Helvetica, sans-serif; margin: 0px; padding: 0px; text-align: center;">
        <v-col dense cols="12">
            <div class="topnav" @click="test">
                <h1>PLC project</h1>
            </div>
        </v-col>
        <v-col dense cols="12">
            <v-row dense>
                <v-col dense cols="3">
                    <v-text-field density="compact" label="Connection" class="center" hide-details style="font-size: 14px;" dense
                        v-model="address" block outlined><template v-slot:append>
                            <v-icon :disabled="loading" style="opacity: 0.65;" :color="color_con" title="Connect"
                                @click="connect">
                                mdi-connection
                            </v-icon>
                        </template></v-text-field>
                </v-col>
                <v-col dense cols="3">
                    <v-text-field density="compact" label="Clock" class="center" hide-details step="100" style="font-size: 14px;" dense
                        v-model="numberValue" :disabled="!f_con" block outlined type="number" min="100" max="10000">
                        <template v-slot:append>
                            <div>&nbsp;&nbsp;&nbsp;</div> <v-icon :disabled="!f_con" style="opacity: 0.65;" color="#0f8b8d"
                                title="Change time" @click="tiempo">
                                mdi-clock-outline
                            </v-icon>
                        </template></v-text-field>
                </v-col>
                <v-col dense cols="3">
                    <v-btn dark :color="check(this.wifi)" class="but" id="wifi" block @click="wifi" :loading="loadingw">WIFI
                        <pre>&nbsp;</pre>
                        <v-icon>mdi-wifi</v-icon>
                    </v-btn>
                </v-col>
                <v-col dense cols="3">
                    <v-btn dark :color="check(this.blue)" class="but" id="blue" block @click="blue"
                        :loading="loadingb">BLUETOOTH
                        <pre>&nbsp;</pre>
                        <v-icon>mdi-bluetooth</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
        <v-col dense cols="12">
            <v-row dense>
                <v-col dense cols="11">
                    <v-progress-linear style="pointer-events: none" v-model="progress" color="#00ff00" height="40">
                        <template v-slot:default="{ value }">
                            <strong>ANALOG: {{ value }}%</strong>
                        </template>
                    </v-progress-linear>
                </v-col> <v-col dense cols="1">
                    <v-dialog persistent v-model="dialogchange" max-width="800">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn dark color="#0f8b8d" class="but" id="change" block v-bind="attrs" v-on="on">
                                <v-icon>mdi-sync</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <h5>Change graph settings?</h5>
                            </v-card-title>
                            <v-card-text class="text-h6">
                                <v-text-field label="Graph limit" class="center" hide-details step="1"
                                    style="font-size: 14px;" dense v-model="limitt" block outlined type="number" min="0"
                                    max="100"></v-text-field>
                                <br>
                                <v-text-field label="Graph iterations" class="center" hide-details step="100"
                                    style="font-size: 14px;" dense v-model="indexmaxt" block outlined type="number" min="100"
                                    max="5000"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="program" text @click="dialogchange = false">
                                    <h4>Cancelar</h4>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn color="program" text @click="changed">
                                    <h4>Aceptar</h4>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
            <Chart class="canvascharta" block :type="type" :data="data0" :options="options0" />
        </v-col>
        <v-col dense cols="4">
            <v-row dense>
                <v-col dense cols="4">
                    <v-btn style="position: relative; top:5px;" dark color="#0f8b8d" class="but" id="toggle_up"
                        @click="toggle(1)"><span style="color: #00ff00;">ON
                        </span></v-btn>
                </v-col>
                <v-col dense cols="4">
                    <v-row dense> <v-spacer></v-spacer>
                        <v-switch inset readonly v-model="led" label="LED" color="#00ff00" hide-details>
                        </v-switch> <v-spacer></v-spacer> </v-row>
                </v-col>
                <v-col dense cols="4">
                    <v-btn style="position: relative; top:5px;" dark color="#0f8b8d" class="but" id="toggle_down"
                        @click="toggle(0)"><span style="color: #ff0000;">OFF
                        </span></v-btn>
                </v-col>
            </v-row>
            <Chart class="canvaschart" block :type="type" :data="data1" :options="options1" />
        </v-col>
        <v-col dense cols="4">
            <v-spacer></v-spacer>
            <v-row dense>
                <v-col dense cols="12">
                    <v-row dense> <v-spacer></v-spacer> <v-switch inset readonly v-model="pin1" label="INPUT_1"
                            color="#00ff00" hide-details>
                        </v-switch><v-spacer></v-spacer> </v-row>
                </v-col>
            </v-row>
            <Chart class="canvaschart" block :type="type" :data="data2" :options="options1" />
            <v-spacer></v-spacer>
        </v-col>
        <v-col dense cols="4">
            <v-row dense>
                <v-col dense cols="12">
                    <v-row dense> <v-spacer></v-spacer> <v-switch inset x-large readonly v-model="pin2" label="INPUT_2"
                            color="#00ff00" hide-details>
                        </v-switch><v-spacer></v-spacer></v-row>
                </v-col>
            </v-row>
            <Chart class="canvaschart" block :type="type" :data="data3" :options="options1" />
        </v-col>
        <div id="charting" style="pointer-events:none; position: absolute; top: 0px; left: 0px; height:200vh; width: 300vh; background-color: rgba(0,0,0,0); z-index: 100;"></div>
    </v-row>
</template>

<style scoped>
h1 {
    font-size: 1.5rem;
    color: white;
}

.canvaschart {
    height: 120px;
    width: auto;
}

.canvascharta {
    height: 350px;
    width: auto;
}

.center>>>input {
    text-align: center
}

.topnav {
    opacity: 0.65;
    overflow: hidden;
    background-color: #0f8b8d;
}

.but {
    opacity: 0.65;
}

.content {
    padding: 10px;
    max-width: 600px;
    margin: 0 auto;
}

.card {
    background-color: #F8F7F9;
    box-shadow: 2px 2px 12px 1px rgba(140, 140, 140, .5);
    padding-top: 10px;
    padding-bottom: 20px;
}

.state {
    font-size: 0.5rem;
    color: #8c8c8c;
    font-weight: bold;
}
</style>
  
<script>
import Vue from "vue";
import Chart from "primevue/chart";
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
export default {
    components: {
        Chart,
    },
    data() {
        return {
            dialogchange: false,
            timeout: 100,
            ready: false,
            loading: false,
            loadingw: false,
            loadingb: false,
            f_con: false,
            f_wifi: false,
            f_blue: false,
            websocket_esp: null,
            address: "192.168.1.101",
            numberValue: 0,
            progress: 0,
            led: false,
            pin1: false,
            pin2: false,
            color_con: "#ff0000",
            limit: 80,
            indexmax: 1000,
            limitt: 80,
            indexmaxt: 1000,
            type: "line",
            data0: {
                labels: [],
                datasets: [
                    {
                        borderColor: "#0000ff",
                        borderWidth: 2,
                        radius: 0,
                        data: []
                    }, {
                        borderColor: "#ff0000",
                        borderWidth: 1,
                        radius: 0,
                        data: []
                    }]
            },
            data1: {
                labels: [],
                datasets: [
                    {
                        borderColor: "#0000ff",
                        borderWidth: 2,
                        radius: 0,
                        data: []
                    }]
            },
            data2: {
                labels: [],
                datasets: [
                    {
                        borderColor: "#0000ff",
                        borderWidth: 2,
                        radius: 0,
                        data: []
                    }]
            },
            data3: {
                labels: [],
                datasets: [
                    {
                        borderColor: "#0000ff",
                        borderWidth: 2,
                        radius: 0,
                        data: []
                    }]

            },
            options1: {
                interaction: {
                    intersect: false
                },
                plugins: {
                    legend: false
                },
                elements: {
                    point: {
                        radius: 0
                    }
                },
                maintainAspectRatio: false,
                animation: false,
                scales: {
                    x: {
                        grid: {
                            display: false
                        },
                    },
                    y: {
                        grid: {
                            display: false
                        },
                        suggestedMin: 0,
                        suggestedMax: 1
                    },
                },
            },
            options0: {
                interaction: {
                    intersect: false
                },
                plugins: {
                    legend: false
                },
                elements: {
                    point: {
                        radius: 0
                    }
                },
                maintainAspectRatio: false,
                animation: false,
                scales: {
                    x: {
                        grid: {
                            display: false
                        },
                    },
                    y: {
                        grid: {
                            display: false
                        },
                        suggestedMin: 0,
                        suggestedMax: 100
                    },
                },
            },
        };
    },
    created() {
        this.$connectionService.start();
        Vue.$connectionService.events.on("plcsendstate", (values) => {
            console.log("web socket server");
        });
        Vue.$connectionService.events.on("plchistory", (values) => {
            console.log(values);
        });
        this.init_ws_esp();
        this.init_chart()
    },
    destroyed() {
        this.websocket_esp.close();
        this.$connectionService.stop();
    },
    methods: {
        init_chart() {
            for (var i = 0; i < this.indexmax; i++) {
                this.data1.labels.push("");
                this.data2.labels.push("");
                this.data3.labels.push("");
                this.data0.labels.push("");
                this.data1.datasets[0].data.push(0);
                this.data2.datasets[0].data.push(0);
                this.data3.datasets[0].data.push(0);
                this.data0.datasets[0].data.push(0);
                this.data0.datasets[1].data.push(this.limit);
            }
        },
        update(value) {
            this.data2.datasets[0].data.splice(0, 1);
            this.data3.datasets[0].data.splice(0, 1);
            this.data0.datasets[0].data.splice(0, 1);
            this.data2.datasets[0].data.push(value[1]);
            this.data3.datasets[0].data.push(value[2]);
            this.data0.datasets[0].data.push(value[3]);
        },
        check(value) {
            if (value) {
                return "#ff0000";
            }
            else {
                return "#00ff00";
            }
        },
        connect() {
            if (this.f_con) {
                this.websocket_esp.close();
            }
            else {
                this.init_ws_esp();
            }
        },
        wifi() {
            if (this.f_con) {
                this.loadingw = true;
                if (this.f_wifi) {
                    console.log("send wifi 0");
                    this.websocket_esp.send("a0");
                }
                else {
                    console.log("send wifi 1");
                    this.websocket_esp.send("a1");
                }
            }
        },
        blue() {
            if (this.f_con) {
                this.loadingb = true;
                if (this.f_blue) {
                    console.log("send blue 0");
                    this.websocket_esp.send("b0");
                }
                else {
                    console.log("send blue 1");
                    this.websocket_esp.send("b1");
                }
            }
        },
        toggle(value) {
            try {
                if (value == 0) {
                    console.log("send led 0");
                    this.websocket_esp.send("l0");
                }
                else if (value == 1) {
                    console.log("send led 1");
                    this.websocket_esp.send("l1");
                }
            }
            catch {
                console.log("ESP no conectado");
            }
        },
        lista() {
            this.$connectionService.plchistory();
        },
        tiempo() {
            if (this.numberValue < 100) {
                this.numberValue = 100;
            } else if (this.numberValue > 10000) {
                this.numberValue = 10000;
            }
            this.websocket_esp.send("t" + this.numberValue);
        },
        changed() {
            this.dialogchange = false;
            if (this.indexmaxt > 5000) {
                this.indexmaxt = 5000;
            }
            else if (this.indexmaxt < 100) {
                this.indexmaxt = 100;
            }
            if (this.limitt > 100) {
                this.limitt = 100;
            }
            else if (this.limitt < 0) {
                this.limitt = 0;
            }
            this.indexmax = this.indexmaxt;
            this.limit = this.limitt;

            const indexes = this.data1.labels.length;

            if (indexes > this.indexmax) {
                const dif = indexes - this.indexmax;
                this.data1.labels.splice(0, dif);
                this.data2.labels.splice(0, dif);
                this.data3.labels.splice(0, dif);
                this.data0.labels.splice(0, dif);
                this.data0.datasets[0].data.splice(0, dif);
                this.data1.datasets[0].data.splice(0, dif);
                this.data2.datasets[0].data.splice(0, dif);
                this.data3.datasets[0].data.splice(0, dif);
            }
            else if (indexes < this.indexmax) {
                const dif = this.indexmax - indexes;
                for (var i = 0; i < dif; i++) {
                    this.data1.labels.unshift("");
                    this.data2.labels.unshift("");
                    this.data3.labels.unshift("");
                    this.data0.labels.unshift("");
                    this.data1.datasets[0].data.unshift(0);
                    this.data2.datasets[0].data.unshift(0);
                    this.data3.datasets[0].data.unshift(0);
                    this.data0.datasets[0].data.unshift(0);
                }
            }
            this.data0.datasets[1].data = [];
            for (var j = 0; j < this.indexmax; j++) {
                this.data0.datasets[1].data.push(this.limit);
            }
        },
        send(value) {
            var bol = true;
            var values = value.split("~");
            if (value[0] == "0") {
                this.led = false;
                this.data1.datasets[0].data.splice(0, 1);
                this.data1.datasets[0].data.push(0);
                bol = false;
            }
            else {
                this.led = true;
                this.data1.datasets[0].data.splice(0, 1);
                this.data1.datasets[0].data.push(1);
            }
            if (value[2] == "0") {
                this.pin1 = false;
                this.data2.datasets[0].data.splice(0, 1);
                this.data2.datasets[0].data.push(0);
                bol = false;
            }
            else {
                this.pin1 = true;
                this.data2.datasets[0].data.splice(0, 1);
                this.data2.datasets[0].data.push(1);
            }
            if (value[4] == "0") {
                this.pin2 = false;
                this.data3.datasets[0].data.splice(0, 1);
                this.data3.datasets[0].data.push(0);
                bol = false;
            }
            else {
                this.pin2 = true;
                this.data3.datasets[0].data.splice(0, 1);
                this.data3.datasets[0].data.push(1);
            }
            this.progress = parseFloat(value.substr(6));
            this.data0.datasets[0].data.splice(0, 1);
            this.data0.datasets[0].data.push(this.progress);
            if (bol && this.progress > this.limit) {
                console.log("ALERT");
                var testing = ["LED~INPUT_1~INPUT_2~ANALOG", value, format(parseISO(new Date().toISOString()), "yyyy-MM-dd HH:mm:ss"), "MS"];
                this.$connectionService.plcsendstate(testing);
                document.getElementById("charting").style.background = "rgba(255,196,0,0.5)";
            }
            else {
                document.getElementById("charting").style.background = "rgba(0,0,0,0)";
            }
        },
        init_ws_esp() {
            this.loading = true;
            console.log('Connecting with ESP...');
            this.websocket_esp = new WebSocket('ws://' + this.address + '/ws');
            this.websocket_esp.onopen = () => {
                this.loading = false;
                this.f_con = true;
                this.color_con = '#00ff00';
                console.log('ESP accepted');
                this.websocket_esp.send('{"protocol":"json","version":1}\x1E');
                console.log('ESP handshake sent');
            };
            this.websocket_esp.onclose = () => {
                this.loading = false;
                this.f_con = false;
                this.color_con = '#ff0000';
                console.log('ESP closed');
            };
            this.websocket_esp.onmessage = (event) => {
                var datos = event.data;
                if (datos[1] == "~") {
                    this.send(datos);
                }
                else {
                    if (datos == "{}\x1E") {
                        console.log('ESP handshake received');
                    }
                    else if (datos == "l0") {
                        console.log("led off");
                        this.led = false;
                    }
                    else if (datos == "l1") {
                        console.log("led on");
                        this.led = true;
                    }
                    else if (datos == "a0") {
                        console.log("wifi disabled");
                        this.loadingw = false;
                        this.f_wifi = false;
                        document.getElementById("wifi").style.background = '#ff0000';
                    }
                    else if (datos == "a1") {
                        console.log("wifi enabled");
                        this.loadingw = false;
                        this.f_wifi = true;
                        document.getElementById("wifi").style.background = '#00ff00';
                    }
                    else if (datos == "b0") {
                        console.log("bluetooth disabled");
                        this.loadingb = false;
                        this.f_blue = false;
                        document.getElementById("blue").style.background = '#ff0000';
                    }
                    else if (datos == "b1") {
                        console.log("bluetooth enabled");
                        this.loadingb = false;
                        this.f_blue = true;
                        document.getElementById("blue").style.background = '#00ff00';
                    }
                    else if (datos[0] == "t") {
                        console.log("time changed " + datos.substr(1));
                        try {
                            this.numberValue = parseInt(datos.substr(1));
                        } catch {
                            this.numberValue = datos.substr(1);
                        }
                    }
                    else {
                        console.log("UNKNOWN");
                        console.log(event.data);
                    }
                }
            };
        },
        test() {
            console.log(this.progress);
            console.log(this.limit);
        },

    },
};
</script>