<template>
  <div>
    <v-card dense class="elevation-1">
      <v-card-title>
        <v-toolbar dense flat>
          <v-btn small icon>
            <v-icon>mdi-chart-bar</v-icon>
          </v-btn>
          <v-toolbar-title>Inicio</v-toolbar-title>
          <v-divider class="mx-4" vertical></v-divider>
          <v-spacer />
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="0" sm="1" md="2" lg="3" xl="4"> </v-col>
          <v-col cols="12" sm="10" md="8" lg="6" xl="4">
            <Chart
              class="canvaschart"
              block
              :type="type1"
              :data="data1"
              :options="options1"
            />
          </v-col>
          <v-col cols="0" sm="1" md="2" lg="3" xl="4"> </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<style scoped>
.canvaschart {
  height: 400px;
  width: auto;
}
</style>

<script>
import Vue from "vue";
import Chart from "primevue/chart";
export default {
  components: {
    Chart,
  },
  data() {
    return {
      type1: "bar",
      data1: {
        labels: ["Registrados", "Faltantes", "Total"],
        datasets: [
          {
            borderColor: ["#43A047", "#FFA726", "#9D2449"],
            backgroundColor: ["#43A047C0", "#FFA726C0", "#9D2449C0"],
            borderWidth: 0,
            borderRadius: 1,
            data: [0, 0, 0],
          },
        ],
      },
      options1: {
        maintainAspectRatio: false,
        animation: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    };
  },
  created() {
    this.$connectionService.start();
    Vue.$connectionService.events.on("getgrafica", (values) => {
      this.refresh(values);
    });
    Vue.$connectionService.events.on("check", (values) => {
      if (values == "si") {
        this.funcionnormal();
      }
    });
    if (this.$store.state.token == "si") {
      this.$connectionService.makeapplogin(
        this.$store.state.usuario,
        this.$store.state.pass
      );
      this.funcionnormal();
    } else {
      this.$router.push({ name: "login" });
    }
  },
  destroyed() {
    this.$connectionService.stop();
  },
  methods: {
    funcionnormal() {
      this.$connectionService.getGrafica(
        this.$store.state.basedatos,
        this.$store.state.usuario
      );
    },
    refresh(values) {
      this.data1.datasets[0].data = [
        values.cantidad,
        values.faltante,
        values.total,
      ];
    },
  },
};
</script>