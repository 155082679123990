import Vue from "vue";
import { EventEmitter } from "events";

import { PluginObject, VueConstructor } from "vue/types/umd";

import { LogLevel, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";


export default class firstService {

    connection1: signalR.HubConnection;
    events: EventEmitter;
    constructor() {
        this.events = new EventEmitter();
        this.connection1 = new HubConnectionBuilder()
            .configureLogging(LogLevel.Trace)
            .withAutomaticReconnect()
            .withUrl("https://wsbingo.contago.com.mx/")
            //.withUrl("https://localhost:5001/")
            .build();

        this.connection1.on("obtener", (values: any) => { this.events.emit("obtener", values) });
        this.connection1.on("obteneradmin", (values: any) => { this.events.emit("obteneradmin", values) });
        this.connection1.on("actualizar", (values: any) => { this.events.emit("actualizar", values) });
        this.connection1.on("agregar", (values: any) => { this.events.emit("agregar", values) });
        this.connection1.on("eliminar", (values: any) => { this.events.emit("eliminar", values) });
        this.connection1.on("login", (values: any) => { this.events.emit("login", values) });
        this.connection1.on("applogin", (values: any) => { this.events.emit("applogin", values) });
        this.connection1.on("getusuarios", (values: any) => { this.events.emit("getusuarios", values) });
        this.connection1.on("addusuarios", (values: any) => { this.events.emit("addusuarios", values) });
        this.connection1.on("delusuarios", (values: any) => { this.events.emit("delusuarios", values) });
        this.connection1.on("getlista", (values: any) => { this.events.emit("getlista", values) });
        this.connection1.on("getlistapart", (values: any) => { this.events.emit("getlistapart", values) });
        this.connection1.on("getgrafica", (values: any) => { this.events.emit("getgrafica", values) });
        this.connection1.on("getgraficapart", (values: any) => { this.events.emit("getgraficapart", values) });
        this.connection1.on("check", (values: any) => { this.events.emit("check", values) });
        this.connection1.on("gethistorial", (values: any) => { this.events.emit("gethistorial", values) });
    
        this.connection1.on("plcsendstate", (values: any) => { this.events.emit("plcsendstate", values) });
        this.connection1.on("plchistory", (values: any) => { this.events.emit("plchistory", values) });
    }

    //
    start() {
        this.connection1.start();
    }
    stop() {
        this.connection1.stop();
    }
    login(values: any) {
        this.events.emit("ini", values);
    }
    //

    getList() {
        if (this.connection1.state === HubConnectionState.Connected) {
            this.connection1.send("obtener");
        }
        else {
            setTimeout(() => this.getList(), 250);
        }
    }

    getListData() {
        if (this.connection1.state === HubConnectionState.Connected) {
            this.connection1.send("obteneradmin");
        }
        else {
            setTimeout(() => this.getListData(), 250);
        }
    }

    setList(campo: string, valor: string, id: string) {
        if (this.connection1.state === HubConnectionState.Connected) {
            this.connection1.send("actualizar", [campo, valor, id]);
        }
        else {
            setTimeout(() => this.setList(campo, valor, id), 250);
        }
    }

    addList(titulo: string, descripcion: string) {
        if (this.connection1.state === HubConnectionState.Connected) {
            this.connection1.send("agregar", [titulo, descripcion]);
        }
        else {
            setTimeout(() => this.addList(titulo, descripcion), 250);
        }
    }

    deleteList(id: string) {
        if (this.connection1.state === HubConnectionState.Connected) {
            this.connection1.send("eliminar", id);
        }
        else {
            setTimeout(() => this.deleteList(id), 250);
        }
    }

    makelogin(usuario: string, pass: string) {
        if (this.connection1.state === HubConnectionState.Connected) {
            this.connection1.send("login", [usuario, pass]);
        }
        else {
            setTimeout(() => this.makelogin(usuario, pass), 250);
        }
    }

    makeapplogin(usuario: string, pass: string) {
        if (this.connection1.state === HubConnectionState.Connected) {
            this.connection1.send("applogin", [usuario, pass]);
        }
        else {
            setTimeout(() => this.makeapplogin(usuario, pass), 250);
        }
    }

    getUsuarios(basedatos: string) {
        if (this.connection1.state === HubConnectionState.Connected) {
            this.connection1.send("getusuarios", basedatos);
        }
        else {
            setTimeout(() => this.getUsuarios(basedatos), 250);
        }
    }

    addUsuarios(usuario: string, pass: string, nombre: string, empresa: string, basedatos: string, admin: string) {
        if (this.connection1.state === HubConnectionState.Connected) {
            this.connection1.send("addusuarios", [usuario, pass, nombre, empresa, basedatos, admin]);
        }
        else {
            setTimeout(() => this.addUsuarios(usuario, pass, nombre, empresa, basedatos, admin), 250);
        }
    }

    delUsuarios(usuario: string, pass: string, basedatos: string, admin: string) {
        if (this.connection1.state === HubConnectionState.Connected) {
            this.connection1.send("delusuarios", [usuario, pass, basedatos, admin]);
        }
        else {
            setTimeout(() => this.delUsuarios(usuario, pass, basedatos, admin), 250);
        }
    }

    getLista(basedatos: string, usuario: string) {
        if (this.connection1.state === HubConnectionState.Connected) {
            this.connection1.send("getlista", [basedatos, usuario]);
        }
        else {
            setTimeout(() => this.getLista(basedatos, usuario), 250);
        }
    }
    
    getListaPart(basedatos: string, codigo: string) {
        if (this.connection1.state === HubConnectionState.Connected) {
            this.connection1.send("getlistapart", [basedatos, codigo]);
        }
        else {
            setTimeout(() => this.getListaPart(basedatos, codigo), 250);
        }
    }

    getGrafica(basedatos: string, usuario: string) {
        if (this.connection1.state === HubConnectionState.Connected) {
            this.connection1.send("getgrafica", [basedatos, usuario]);
        }
        else {
            setTimeout(() => this.getGrafica(basedatos, usuario), 250);
        }
    }

    getGraficaPart(basedatos: string, codigo: string) {
        if (this.connection1.state === HubConnectionState.Connected) {
            this.connection1.send("getgraficapart", [basedatos, codigo]);
        }
        else {
            setTimeout(() => this.getGraficaPart(basedatos, codigo), 250);
        }
    }

    check(basedatos: string, usuario: string, id_mex: string, value: string) {
        if (this.connection1.state === HubConnectionState.Connected) {
            this.connection1.send("check", [basedatos, usuario, id_mex, value]);
        }
        else {
            setTimeout(() => this.check(basedatos, usuario, id_mex, value), 250);
        }
    }

    plcsendstate(datos: string[]) {
        if (this.connection1.state === HubConnectionState.Connected) {
            this.connection1.send("plcsendstate", datos);
        }
        else {
            setTimeout(() => this.plcsendstate(datos), 250);
        }
    }

    plchistory() {
        if (this.connection1.state === HubConnectionState.Connected) {
            this.connection1.send("plchistory");
        }
        else {
            setTimeout(() => this.plchistory(), 250);
        }
    }
}

export const ConnectionServices: PluginObject<any> = {
    install(Vue: VueConstructor<Vue>, option: any | undefined) {
        Vue.$connectionService = new firstService();
        Vue.prototype.$connectionService = Vue.$connectionService;
    }
}