<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      disable-sort
      sort-by="id_cuenta"
      class="elevation-1"
      :footer-props="{ itemsPerPageText: 'Filas por página:' }"
      :items-per-page="-1"
    >
      <template v-slot:top>
        <v-card-title>
          <v-toolbar dense flat>
            <v-btn small icon>
              <v-icon>mdi-account-group</v-icon>
            </v-btn>
            <v-toolbar-title>Usuarios</v-toolbar-title>
            <v-divider class="mx-4" vertical></v-divider>
            <v-spacer />
            <v-dialog v-model="dialognew" persistent max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="program" dark v-bind="attrs" v-on="on">
                  Nuevo
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span><v-toolbar-title>Nuevo</v-toolbar-title></span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row dense>
                      <v-col dense cols="12">
                        <v-text-field
                          v-model="nuevo.nombre"
                          label="Nombre"
                          color="program"
                          dense
                          small
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col dense cols="6">
                        <v-text-field
                          v-model="nuevo.usuario"
                          label="Usuario"
                          color="program"
                          dense
                          small
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col dense cols="6">
                        <v-text-field
                          v-model="nuevo.pass"
                          label="Contraseña"
                          color="program"
                          dense
                          small
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="program" text @click="closedialog">
                    Cancelar
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="program" text @click="savedialog">
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </v-card-title>
      </template>
      <template v-slot:no-data>
        <br />
        <p>Sin datos disponibles</p>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <template v-if="item.tipo != 'Admin'">
          <v-icon title="Eliminar" @click="del(item.usuario, item.pass)">
            mdi-delete
          </v-icon>
        </template>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  data() {
    return {
      dialognew: false,
      headers: [
        { text: "Usuario", value: "usuario", align: "center" },
        { text: "Titulo", value: "pass", align: "center" },
        { text: "Nombre", value: "nombre", align: "center" },
        { text: "Tipo", value: "tipo", align: "center" },
        {
          text: "Eliminar",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      items: [],
      nuevo: { nombre: "", usuario: "", pass: "" },
    };
  },
  created() {
    this.$connectionService.start();
    Vue.$connectionService.events.on("getusuarios", (values) => {
      this.refresh(values);
    });
    Vue.$connectionService.events.on("addusuarios", (values) => {
      this.refresh(values);
    });
    Vue.$connectionService.events.on("delusuarios", (values) => {
      this.refresh(values);
    });
    if (this.$store.state.tipo != "Admin") {
      this.$router.push({ name: "home" });
    } else if (this.$store.state.token == "si") {
      this.$connectionService.makeapplogin(
        this.$store.state.usuario,
        this.$store.state.pass
      );
      this.funcionnormal();
    } else {
      this.$router.push({ name: "login" });
    }
  },
  destroyed() {
    this.$connectionService.stop();
  },
  methods: {
    funcionnormal() {
      this.$connectionService.getUsuarios(this.$store.state.basedatos);
    },
    refresh(values) {
      this.items = [];
      this.items = values;
    },
    add(usuario, pass, nombre, empresa, basedatos) {
      this.$connectionService.addUsuarios(
        usuario,
        pass,
        nombre,
        empresa,
        basedatos,
        this.$store.state.usuario
      );
    },
    del(usuario, pass) {
      this.$connectionService.delUsuarios(
        usuario,
        pass,
        this.$store.state.basedatos,
        this.$store.state.usuario
      );
    },
    closedialog() {
      this.dialognew = false;
      this.nuevo = { nombre: "", usuario: "", pass: "" };
    },
    savedialog() {
      this.add(
        this.nuevo.usuario,
        this.nuevo.pass,
        this.nuevo.nombre,
        this.$store.state.empresa,
        this.$store.state.basedatos
      );
      this.dialognew = false;
      this.nuevo = { nombre: "", usuario: "", pass: "" };
    },
  },
};
</script>
