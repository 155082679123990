import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { ConnectionServices } from './services/firstService'
import axios from 'axios'

Vue.config.productionTip = false
Vue.use(ConnectionServices);

//Vue.config.productionTip = false;
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Authorization'] = 'Bearer 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';
//axios.defaults.baseURL = "http://localhost:5000/";
axios.defaults.baseURL = "https://wsbingo.contago.com.mx/";

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

