<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      disable-sort
      sort-by="id_cuenta"
      class="elevation-1"
      :footer-props="{ itemsPerPageText: 'Filas por página:' }"
      :items-per-page="-1"
    >
      <template v-slot:top>
        <v-card-title>
          <v-toolbar dense flat>
            <v-btn small icon>
              <v-icon>mdi-cogs</v-icon>
            </v-btn>
            <v-toolbar-title>Sistema</v-toolbar-title>
            <v-divider class="mx-4" vertical></v-divider>
            <v-spacer />
            <v-dialog v-model="dialognew" persistent max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="program" dark v-bind="attrs" v-on="on">
                  Nuevo
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span><v-toolbar-title>Nuevo</v-toolbar-title></span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row dense>
                      <v-col dense cols="12">
                        <v-text-field
                          v-model="nuevo.titulo"
                          label="Título"
                          color="program"
                          dense
                          small
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="nuevo.descripcion"
                          label="Descripción"
                          color="program"
                          dense
                          small
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="program" text @click="closedialog">
                    Cancelar
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="program" text @click="savedialog">
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-divider class="mx-4" vertical></v-divider>
            <v-btn icon color="program" @click="funcionnormal"
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
            <v-divider class="mx-4" vertical></v-divider>
            <v-btn icon color="program" @click="funcionadmin"
              ><v-icon>mdi-cog-refresh-outline</v-icon></v-btn
            >
          </v-toolbar>
        </v-card-title>
      </template>
      <template v-slot:no-data>
        <br />
        <p>Sin datos disponibles</p>
      </template>
      <template v-slot:[`item.titulo`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.titulo"
          large
          @save="save('titulo', item.titulo, item.id)"
        >
          <div>{{ item.titulo }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">Editar Titulo</div>
            <v-text-field
              v-model="item.titulo"
              label="Editar"
              single-line
              autofocus
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.descripcion`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.descripcion"
          large
          @save="save('descripcion', item.descripcion, item.id)"
        >
          <div>{{ item.descripcion }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">Editar Descripcion</div>
            <v-text-field
              v-model="item.descripcion"
              label="Editar"
              single-line
              autofocus
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon title="Eliminar" @click="del(item.id)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  data() {
    return {
      dialognew: false,
      headers: [
        { text: "Id", value: "id", align: "center" },
        { text: "Titulo", value: "titulo", align: "center" },
        { text: "Descripcion", value: "descripcion", align: "center" },
        {
          text: "Eliminar",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      items: [],
      item: { id: "", titulo: "", descripcion: "" },
      nuevo: { titulo: "", descripcion: "" },
    };
  },
  created() {
    this.$connectionService.start();
    Vue.$connectionService.events.on("obtener", (values) => {
      this.refresh(values);
    });
    Vue.$connectionService.events.on("obteneradmin", (values) => {
      this.refresh(values);
    });
    Vue.$connectionService.events.on("actualizar", (values) => {
      this.refresh(values);
    });
    Vue.$connectionService.events.on("agregar", (values) => {
      this.refresh(values);
    });
    Vue.$connectionService.events.on("eliminar", (values) => {
      this.refresh(values);
    });
    if (this.$store.state.token == "si") {
      this.$connectionService.makeapplogin(
        this.$store.state.usuario,
        this.$store.state.pass
      );
      this.funcionnormal();
    } else {
      this.$router.push({ name: "login" });
    }
  },
  destroyed() {
    this.$connectionService.stop();
  },
  methods: {
    refresh(values) {
      this.items = [];
      for (var i = 0; i < values.length; i++) {
        this.items.push({
          id: values[i].id,
          titulo: values[i].titulo,
          descripcion: values[i].descripcion,
        });
      }
    },
    funcionnormal() {
      this.$connectionService.getList();
    },
    funcionadmin() {
      this.$connectionService.getListData();
    },
    save(campo, valor, id) {
      this.$connectionService.setList(campo, valor, id);
    },
    add(titulo, descripcion) {
      this.$connectionService.addList(titulo, descripcion);
    },
    del(id) {
      this.$connectionService.deleteList(id);
    },
    closedialog() {
      this.dialognew = false;
      this.nuevo = { titulo: "", descripcion: "" };
    },
    savedialog() {
      this.add(this.nuevo.titulo, this.nuevo.descripcion);
      this.dialognew = false;
      this.nuevo = { titulo: "", descripcion: "" };
    },
  },
};
</script>
